<template>
  <div class="column">
    <div class="top_back">
      <img
        class="back"
        src="../../../assets/img/home/home/PES/icon_pes_back.png"
        @click="back"
      />
      <div class="title">PES报告</div>
      <div class="oldEnter" @click="clickOldEnter">旧版报告入口</div>
    </div>
    <div class="row_normal">
      <div class="flex"></div>
      <div class="one_share" @click="goShareAll">一键分享</div>
    </div>
    <div class="report_bg">
      <iframe class="bottom" :src="url" scrolling="no"></iframe>
    </div>

    <div class="h100"></div>
    <el-dialog :visible.sync="imgShow" center @click="cancel1" width="300px">
      <div class="column_center">
        <img class="big_img" :src="currentCodeUrl"/>
        <a class="down_code" :href="currentCodeUrl">下载二维码</a>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "reportInfo",
  components: {},
  data() {
    return {
      url: "",
      key: "",
      shareUrl: "",
      currentCodeUrl: "",
      imgShow: false,
    };
  },
  mounted() {
    this.key = this.$route.query.key;
    let type = this.$route.query.type;
    if (type == 1) {
      this.url = this.constant.REPORT_SCHOOL + "?key=" + this.key;
    } else {
      this.url = this.constant.REPORT_CHILDREN + "?key=" + this.key;
    }
    console.log(this.url);
    this.getShareImageUrl();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    bindStu(val) {
      console.log(val);
    },
    clickOldEnter() {
      this.$router.push({
        path: "/PESHome",
      });
    },
    goShareAll() {
      this.currentCodeUrl = this.shareUrl;
      this.imgShow = true;
    },
    cancel1() {
      this.imgShow = false;
    },
    // 获取分享二维码
    getShareImageUrl() {
      let params = {
        key: this.key,
      };
      this.api.pes.getShareImageUrl(params).then((res) => {
        if (res.code == 20000) {
          this.shareUrl = res.data.codeUrl;
        }
      });
    },
    savePic() {
      const blob = new Blob([content], { type: "application/vnd.ms-excel" });
      const elink = document.createElement("a");
      elink.download = this.$route.query.name + "学生名单.xlsx";
      elink.style.display = "none";
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href);
      document.body.removeChild(elink);
      this.$notice({
        type: "success",
        message: "下载成功",
      }).isShow();
    },
  },
  computed: {},
};
</script>
      <style lang="scss" scoped>
.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #e7e7e7;
}
.top_back {
  position: relative;
  height: 42px;
  background: #333333;
  .back {
    position: absolute;
    top: 13px;
    left: 140px;
    width: 42px;
    height: 15px;
    cursor: pointer;
  }
  .title {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
  .oldEnter {
    position: absolute;
    top: 7px;
    right: 140px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cf5d5d;
    cursor: pointer;
  }
}
.row_normal {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.month_lay {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #333333;
}
.w1000 {
  width: 1200px;
  height: 40px;
  overflow-x: scroll;
}
.swip-item {
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
}
.month_normal {
  width: 70px;
  height: 30px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
.text_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.flex {
  flex: 1;
}
.content {
  width: 1200px;
  background: #fff;
  margin-top: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 50px;
}
.pes-select {
  display: flex;
  .select-item {
    width: 130px;
    height: 38px;
    margin-right: 32px;
    /deep/ .el-input__inner {
      font-size: 18px;
    }
  }
}
.pl_input {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b02424;
  margin-left: 20px;
  margin-right: 30px;
}
.h100 {
  height: 100px;
}
.ys_text {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b02424;
  margin-left: 30px;
}
.mt30 {
  margin-top: 30px;
}
.month_list {
  width: 1140px;
  display: grid;
  grid-template-columns: repeat(4, 250px);
  /* justify-content: center; */
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
  .normal {
    width: 250px;
    height: 430px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-top: 10px;
    .grade_name {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #6b6161;
      position: absolute;
      top: 10px;
      left: 0px;
      width: 100%;
    }
    .img_ha {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #e10500;
    }
    .img_no {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #f9f9f9;
    }
  }
  .pes_normal {
    position: relative;
    width: 100%;
    height: 380px;
  }
  .create_report {
    width: 250px;
    height: 30px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-top: 10px;
    background: #e10500;
    border-radius: 10px;
    cursor: pointer;
  }
}

.upWT {
  height: 50px;
  line-height: 50px;
  background: #b93138;
  color: #fff;
}

.close {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 15px;
  height: 15px;
  background: url(../../../assets/img/course/Icon_tc_close.png) no-repeat;
  background-size: cover;
}

/deep/ .el-input__suffix {
  line-height: 0.5rem;
}
.riseConter {
  padding: 30px;

  p {
    color: #333;
    line-height: 45px;
  }
}
.hint {
  color: red !important;
}

.hintMailbox {
  font-size: 14px;
  font-weight: 400;
  color: #cb1b32;
  text-align: center;
}

.btn {
  width: 100px;
  height: 38px;
  //background: none;
  //border:none;
  border: 1px solid #ba3037;
  border-radius: 6px;
  background: #fff;
  color: #ba3037;
  font-size: 18px;
  margin: 0 25px;
}

.active {
  background: linear-gradient(232deg, #b93037, #ef525b);
  box-shadow: 0 3px 6px 0 rgba(255, 67, 77, 0.33);
  color: #fff;
  border: 1px solid transparent;
}

/deep/ .el-dialog__header {
  padding: 0;
  position: relative;
}

/deep/ .el-dialog__headerbtn {
  top: 0.15rem;
  opacity: 0;
}
.pop {
  width: 100%;
  height: 100%;
  background: #00000060;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  .pop-content {
    width: 620px;
    height: 308px;
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 10px 43px 0px rgba(136, 136, 136, 0.3);
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .close {
      position: absolute;
      right: 19px;
      top: 17px;
      cursor: pointer;
      z-index: 1;
      img {
        width: 15px;
        height: 15px;
      }
    }
    .top_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 620px;
      height: 50px;
      background: #b83138;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .text {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 50px;
        text-align: center;
      }
    }
    .row {
      display: flex;
      flex-direction: row;

      width: 620px;
      height: 228px;
      position: absolute;
      top: 50px;
      left: 0;
      img {
        width: 152px;
        height: 119px;
      }
    }
    .flex {
      flex: 1;
    }
    .column {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
    }
    .news {
      width: 160px;
      height: 42px;
      background: linear-gradient(50deg, #b83037, #d24249);
      box-shadow: 0px 3px 6px 0px rgba(231, 62, 74, 0.33);
      border-radius: 21px;
      text-align: center;
      line-height: 42px;
      cursor: pointer;
      display: flex;
      font-size: 18px;
      color: #ffffff;
      justify-content: center;
      align-items: center;
      margin-top: 12px;
    }
    .news:hover {
      background: linear-gradient(50deg, #dd3b44, #f74f58);
      box-shadow: 0px 3px 6px 0px rgba(231, 62, 74, 0.33);
    }
    .old {
      width: 160px;
      height: 42px;
      background: #ffffff;
      border: 1px solid #b83138;
      border-radius: 20px;
      text-align: center;
      line-height: 42px;
      cursor: pointer;
      display: flex;
      font-size: 18px;
      color: #b83138;
      justify-content: center;
      align-items: center;
      margin-top: 12px;
    }
    .kx_text {
      font-size: 18px;
      color: #999999;
      justify-content: center;
      align-items: center;
    }
  }
}
.report_bg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.one_share {
  font-size: 26px;
  color: #333;
  margin-right: 630px;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.bottom {
  width: 375px;
  min-height: 2000px;
}
.big_img {
  width: 300px;
  height: 300px;
}
.column_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.down_code {
  cursor: pointer;
  font-size: 20px;
  color: #333333;
  margin-top: 10px;
}
</style>